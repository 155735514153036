<template>
  <div>
    <c-mobile-table
      ref="table"
      :title="'\'' + popupParam.requestContents + '\'에 대한 즉시조치 목록'"
      tableId="imprRequest"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeightAuto="true"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      selection="multiple"
      rowKey="sopImprovementId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn v-if="editable" label="Excel 업로드" icon="add" @btnClicked="openExcelUploader" /> -->
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addImpr" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="저장" 
            icon="save"
            @beforeAction="saveImprRequest"
            @btnCallback="saveCallback" />
          <!-- <c-btn v-if="editable" label="개선요청 추가" icon="save" @btnClicked="addImprRequest" /> -->
          <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removeImpr" />
        </q-btn-group>
      </template>
    </c-mobile-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-immediate',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          requestContents: '',
          ibmTaskTypeCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.saveUrl = transactionConfig.sop.ibm.improve.insert.multi.url
      // code 
      this.$comm.uploderSetting('IBM_BEFORE').then(_result => {
        this.$comm.uploderSetting('IBM_AFTER').then(_result2 => {
        this.grid.columns = [
            {
              name: 'plantCd',
              field: 'plantCd',
              label: '사업장',
              align: 'center',
              style: 'width:100px',
              sortable: false,
              type: 'plant',
              required: true,
              colClass: 6,
            },
            {
              name: 'actionCompleteDate',
              field: 'actionCompleteDate',
              label: '조치완료일',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'date',
              required: true,
              colClass: 6,
            },
            {
              name: 'actionDeptName',
              field: 'actionDeptName',
              label: '조치부서',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'dept',
              deptCd: 'actionDeptCd',
              required: true,
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: '제목',
              align: 'left',
              style: 'width:300px',
              sortable: false,
              type: 'text',
              required: true,
            },
            {
              name: 'actionContents',
              field: 'actionContents',
              label: '조치내용',
              align: 'left',
              style: 'width:400px',
              sortable: false,
              type: 'textarea',
              required: true,
            },
            {
              name: 'before',
              field: 'before',
              label: '개선 전 사진',
              align: 'center',
              style: 'width:200px',
              type: 'attach',
              taskClassCd: 'IBM_BEFORE',
              keyText: 'sopImprovementId',
              sortable: false,
              uploaderSetting: _result,
            },
            {
              name: 'after',
              field: 'after',
              label: '개선 후 사진',
              align: 'center',
              style: 'width:200px',
              type: 'attach',
              taskClassCd: 'IBM_AFTER',
              keyText: 'sopImprovementId',
              sortable: false,
              uploaderSetting: _result2,
            },
          ]
        })
      })
      // list setting
    },
    /* eslint-disable no-unused-vars */
    // openExcelUploader() {
    //   this.popupOptions.title = '즉시개선 엑셀 업로드'; // 즉시개선
    //   this.popupOptions.param = this.popupParam;
    //   this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediateExcelUpload.vue'}`);
    //   this.popupOptions.width = '80%';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup(_result) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;

    //   if (_result && _result.length > 0) {
    //     let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
    //     this.$_.forEach(s_data, item => {
    //       item.relationTableKey = this.popupParam.relationTableKey;
    //       item.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
    //       item.ibmStepCd = 'IS00000020';
    //       item.ibmClassCd = 'IC00000005';
    //       item.improveRequestDeptCd = this.$store.getters.user.deptCd;
    //       item.improveRequestDeptName = this.$store.getters.user.deptName;
    //       item.improveRequestUserId = this.$store.getters.user.userId;
    //       item.improveRequestUserName = this.$store.getters.user.userName;
    //       item.regUserId = this.$store.getters.user.userId;
    //       item.regDt = this.$comm.getToday();
    //       item.editFlag = 'C';
    //     })
    //     this.grid.data = this.$_.concat(this.grid.data, s_data)
    //   }
    // },
    addImpr() {
      this.grid.data.push({
        plantCd: this.$store.getters.user.plantCd,
        plantName: this.$store.getters.user.plantName,
        actionDeptCd: this.$store.getters.user.deptCd,
        actionDeptName: this.$store.getters.user.deptName,
        ibmTitle: this.popupParam.requestContents + '_' + this.$comm.getToday(),
        actionContents: '',
        actionCompleteDate: this.$comm.getToday(),
        sopImprovementId: uid(),
        relationTableKey: this.popupParam.relationTableKey,
        ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
        ibmStepCd: 'IS00000020',
        ibmClassCd: 'IC00000005',
        improveRequestDeptCd: this.$store.getters.user.deptCd,
        improveRequestDeptName: this.$store.getters.user.deptName,
        improveRequestUserId: this.$store.getters.user.userId,
        improveRequestUserName: this.$store.getters.user.userName,
        regUserId: this.$store.getters.user.userId,
        regDt: this.$comm.getToday(),
        editFlag: 'C',
      })
    },
    saveImprRequest() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '목록에 표시된 즉시조치를 \'' + this.popupParam.requestContents + '\'항목에 대한 즉시개선건으로 추가합니다.\n\r 저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        relationTableKey: this.popupParam.relationTableKey,
        ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
      }
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$emit('closePopup', _result.data);
      },);
    },
    removeImpr() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data.splice(this.$_.findIndex(this.grid.data, { sopImprovementId: item.sopImprovementId }), 1)
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
        
      }
    }
  }
};
</script>
